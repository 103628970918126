import { Event } from "../baseTypes";
import { CatStatus, CatAttribute } from "../API";

const inHouseEvents: Event[] = [
  {
    id: "e_1",
    title: "",
    imgUrl:
      "https://cat-daily-event-images.s3-ap-southeast-2.amazonaws.com/1.jpeg",
    status: CatStatus.inHouse,
    content: "听闻家中两脚兽的房间传来奇怪的喘息。去观察？",
    result: "本宝宝看到了什么？幼小的心灵受到了震撼",
    yesEffects: [
      {
        __typename: "EventEffect",
        key: CatAttribute.knowledge,
        delta: 5,
      },
      {
        __typename: "EventEffect",
        key: CatAttribute.wilderness,
        delta: -5,
      },
    ],
    noEffects: null,
    yesItemName: null,
    isChildEvent: false,
    yesEventID: null,
    noEventID: null,
    newStatus: null,
    yesRequirements: null,
    noRequirements: null,
    repeatable: false,
    sceneId: null,
  },
  {
    id: "e_2",
    title: "",
    imgUrl:
      "https://cat-daily-event-images.s3-ap-southeast-2.amazonaws.com/2.png",
    status: CatStatus.inHouse,
    content: "在餐桌上发现了火腿。吃？",
    result: "",
    yesEffects: [
      {
        __typename: "EventEffect",
        key: CatAttribute.health,
        delta: 8,
      },
      {
        __typename: "EventEffect",
        key: CatAttribute.wilderness,
        delta: 8,
      },
    ],
    noEffects: null,
    yesItemName: null,
    isChildEvent: false,
    yesEventID: null,
    noEventID: null,
    newStatus: null,
    yesRequirements: null,
    noRequirements: null,
    repeatable: false,
    sceneId: null,
  },
  {
    id: "e_3",
    title: "",
    imgUrl:
      "https://cat-daily-event-images.s3-ap-southeast-2.amazonaws.com/e_3.jpg",
    status: CatStatus.inHouse,
    content: "睡觉时惨遭铲屎官蹂躏。咬？",
    result: "",
    yesEffects: [
      {
        __typename: "EventEffect",
        key: CatAttribute.knowledge,
        delta: 5,
      },
      {
        __typename: "EventEffect",
        key: CatAttribute.wilderness,
        delta: 10,
      },
    ],
    noEffects: null,
    yesItemName: null,
    isChildEvent: false,
    yesEventID: null,
    noEventID: null,
    newStatus: null,
    yesRequirements: null,
    noRequirements: null,
    repeatable: false,
    sceneId: null,
  },
  {
    id: "e_4",
    title: "",
    imgUrl:
      "https://cat-daily-event-images.s3-ap-southeast-2.amazonaws.com/e_4.jpg",
    status: CatStatus.inHouse,
    content: "网恋中的阿强想和你视频。营业？",
    result: "",
    yesEffects: [
      {
        __typename: "EventEffect",
        key: CatAttribute.knowledge,
        delta: 3,
      },
      {
        __typename: "EventEffect",
        key: CatAttribute.wilderness,
        delta: 8,
      },
    ],
    noEffects: null,
    yesItemName: null,
    isChildEvent: false,
    yesEventID: null,
    noEventID: null,
    newStatus: null,
    yesRequirements: null,
    noRequirements: null,
    repeatable: false,
    sceneId: null,
  },
  {
    id: "e_5",
    title: "",
    imgUrl:
      "https://cat-daily-event-images.s3-ap-southeast-2.amazonaws.com/e_5.jpg",
    status: CatStatus.inHouse,
    content: "家里的两脚兽说，鱼吃的太多了，也得吃点水果。同意？",
    result: "",
    yesEffects: [
      {
        __typename: "EventEffect",
        key: CatAttribute.health,
        delta: 5,
      },
    ],
    noEffects: null,
    yesItemName: null,
    isChildEvent: false,
    yesEventID: null,
    noEventID: null,
    newStatus: null,
    yesRequirements: null,
    noRequirements: null,
    repeatable: false,
    sceneId: null,
  },
  {
    id: "e_6",
    title: "",
    imgUrl:
      "https://cat-daily-event-images.s3-ap-southeast-2.amazonaws.com/e_6.jpg",
    status: CatStatus.inHouse,
    content: "为了捕鸟，我要练习飞翔。",
    result: "惨遭失败",
    yesEffects: [
      {
        __typename: "EventEffect",
        key: CatAttribute.knowledge,
        delta: -7,
      },
    ],
    noEffects: null,
    yesItemName: null,
    isChildEvent: false,
    yesEventID: null,
    noEventID: null,
    newStatus: null,
    yesRequirements: null,
    noRequirements: null,
    repeatable: false,
    sceneId: null,
  },
  {
    id: "e_7",
    title: "",
    imgUrl:
      "https://cat-daily-event-images.s3-ap-southeast-2.amazonaws.com/e_7.jpg",
    status: CatStatus.inHouse,
    content: "全家最好的晒太阳点竟是铲屎的的窝。占了？",
    result: "",
    yesEffects: [
      {
        __typename: "EventEffect",
        key: CatAttribute.health,
        delta: 3,
      },
      {
        __typename: "EventEffect",
        key: CatAttribute.wilderness,
        delta: 6,
      },
    ],
    noEffects: null,
    yesItemName: null,
    isChildEvent: false,
    yesEventID: null,
    noEventID: null,
    newStatus: null,
    yesRequirements: null,
    noRequirements: null,
    repeatable: false,
    sceneId: null,
  },
  {
    id: "e_8",
    title: "",
    imgUrl:
      "https://cat-daily-event-images.s3-ap-southeast-2.amazonaws.com/e_8.jpg",
    status: CatStatus.inHouse,
    content: "偶尔在铲屎的怀里睡个觉也不错吧？",
    result: "",
    yesEffects: [
      {
        __typename: "EventEffect",
        key: CatAttribute.wilderness,
        delta: -15,
      },
    ],
    noEffects: null,
    yesItemName: null,
    isChildEvent: false,
    yesEventID: null,
    noEventID: null,
    newStatus: null,
    yesRequirements: null,
    noRequirements: null,
    repeatable: false,
    sceneId: null,
  },
  {
    id: "e_9",
    title: "",
    imgUrl:
      "https://cat-daily-event-images.s3-ap-southeast-2.amazonaws.com/e_9.jpg",
    status: CatStatus.inHouse,
    content: "作为一只高雅的猫子，艺术也不能放下",
    result: "",
    yesEffects: [
      {
        __typename: "EventEffect",
        key: CatAttribute.knowledge,
        delta: 9,
      },
    ],
    noEffects: null,
    yesItemName: null,
    isChildEvent: false,
    yesEventID: null,
    noEventID: null,
    newStatus: null,
    yesRequirements: null,
    noRequirements: null,
    repeatable: false,
    sceneId: null,
  },
  {
    id: "e_10",
    title: "",
    imgUrl:
      "https://cat-daily-event-images.s3-ap-southeast-2.amazonaws.com/e_10.JPG",
    status: CatStatus.inHouse,
    content: "铲屎的又傻了，把自己关在水缸里还要痛苦的叫。去救吗？",
    result: "自己也落进浴缸，成了落汤猫",
    yesEffects: [
      {
        __typename: "EventEffect",
        key: CatAttribute.health,
        delta: -7,
      },
      {
        __typename: "EventEffect",
        key: CatAttribute.wilderness,
        delta: 6,
      },
    ],
    noEffects: null,
    yesItemName: null,
    isChildEvent: false,
    yesEventID: null,
    noEventID: null,
    newStatus: null,
    yesRequirements: null,
    noRequirements: null,
    repeatable: false,
    sceneId: null,
  },
  {
    id: "e_11",
    title: "",
    imgUrl:
      "https://cat-daily-event-images.s3-ap-southeast-2.amazonaws.com/e_11.jpg",
    status: CatStatus.inHouse,
    content: "被撸的有点舒服……猫……",
    result: "",
    yesEffects: [
      {
        __typename: "EventEffect",
        key: CatAttribute.health,
        delta: 8,
      },
      {
        __typename: "EventEffect",
        key: CatAttribute.wilderness,
        delta: -15,
      },
    ],
    noEffects: null,
    yesItemName: null,
    isChildEvent: false,
    yesEventID: null,
    noEventID: null,
    newStatus: null,
    yesRequirements: null,
    noRequirements: null,
    repeatable: false,
    sceneId: null,
  },
  {
    id: "e_12",
    title: "",
    imgUrl:
      "https://cat-daily-event-images.s3-ap-southeast-2.amazonaws.com/e_12.jpg",
    status: CatStatus.inHouse,
    content: "家里来了个美艳小弟，要把它收编进后宫吗？",
    result: "惨遭小弟暴打",
    yesEffects: [
      {
        __typename: "EventEffect",
        key: CatAttribute.health,
        delta: -12,
      },
      {
        __typename: "EventEffect",
        key: CatAttribute.knowledge,
        delta: 9,
      },
    ],
    noEffects: null,
    yesItemName: null,
    isChildEvent: false,
    yesEventID: null,
    noEventID: null,
    newStatus: null,
    yesRequirements: null,
    noRequirements: null,
    repeatable: false,
    sceneId: null,
  },
  {
    id: "e_13",
    title: "",
    imgUrl:
      "https://cat-daily-event-images.s3-ap-southeast-2.amazonaws.com/e_13.jpg",
    status: CatStatus.inHouse,
    content: "铲屎的胆敢抢我的王座，要给他一点厉害康康吗？",
    result: "惨遭铲屎官高速旋转",
    yesEffects: [
      {
        __typename: "EventEffect",
        key: CatAttribute.health,
        delta: -5,
      },
      {
        __typename: "EventEffect",
        key: CatAttribute.knowledge,
        delta: -10,
      },
    ],
    noEffects: null,
    yesItemName: null,
    isChildEvent: false,
    yesEventID: null,
    noEventID: null,
    newStatus: null,
    yesRequirements: null,
    noRequirements: null,
    repeatable: false,
    sceneId: null,
  },
  {
    id: "e_14",
    title: "",
    imgUrl:
      "https://cat-daily-event-images.s3-ap-southeast-2.amazonaws.com/e_14.jpg",
    status: CatStatus.inHouse,
    content: "要学习如何与铲屎的工作时和谐共处吗？",
    result: "",
    yesEffects: [
      {
        __typename: "EventEffect",
        key: CatAttribute.knowledge,
        delta: 13,
      },
      {
        __typename: "EventEffect",
        key: CatAttribute.wilderness,
        delta: -7,
      },
    ],
    noEffects: null,
    yesItemName: null,
    isChildEvent: false,
    yesEventID: null,
    noEventID: null,
    newStatus: null,
    yesRequirements: null,
    noRequirements: null,
    repeatable: false,
    sceneId: null,
  },
  {
    id: "ih_15",
    title: "",
    imgUrl:
      "https://cat-daily-event-images.s3-ap-southeast-2.amazonaws.com/ih_15.jpg",
    status: CatStatus.inHouse,
    content: "生活不易，我有时也要向铲屎官出卖一下色相吗？",
    result: "",
    yesEffects: [
      {
        __typename: "EventEffect",
        key: CatAttribute.wilderness,
        delta: -12,
      },
      {
        __typename: "EventEffect",
        key: CatAttribute.knowledge,
        delta: 8,
      },
    ],
    noEffects: null,
    yesItemName: null,
    isChildEvent: false,
    yesEventID: null,
    noEventID: null,
    newStatus: null,
    yesRequirements: null,
    noRequirements: null,
    repeatable: false,
    sceneId: null,
  },
  {
    id: "ih_16",
    title: "",
    imgUrl:
      "https://cat-daily-event-images.s3-ap-southeast-2.amazonaws.com/ih_16.jpg",
    status: CatStatus.inHouse,
    content: "外面的世界很精彩，我要撕破纱窗吗？",
    result: "进入新状态：流浪",
    yesEffects: [
      {
        __typename: "EventEffect",
        key: CatAttribute.wilderness,
        delta: 15,
      },
    ],
    noEffects: [],
    yesItemName: null,
    isChildEvent: false,
    yesEventID: null,
    noEventID: null,
    newStatus: CatStatus.stray,
    yesRequirements: null,
    noRequirements: null,
    repeatable: true,
    sceneId: null,
  },
  {
    id: "ih_17",
    title: "",
    imgUrl:
      "https://cat-daily-event-images.s3-ap-southeast-2.amazonaws.com/ih_17.jpg",
    status: CatStatus.inHouse,
    content: "虽然我已经有了绝世美颜，要不要也健个身？",
    result: "",
    yesEffects: [
      {
        __typename: "EventEffect",
        key: CatAttribute.health,
        delta: 12,
      },
      {
        __typename: "EventEffect",
        key: CatAttribute.wilderness,
        delta: 6,
      },
    ],
    noEffects: [
      {
        __typename: "EventEffect",
        key: CatAttribute.health,
        delta: -8,
      },
    ],
    yesItemName: null,
    isChildEvent: false,
    yesEventID: null,
    noEventID: null,
    newStatus: null,
    yesRequirements: null,
    noRequirements: null,
    repeatable: false,
    sceneId: null,
  },
  {
    id: "ih_18",
    title: "",
    imgUrl:
      "https://cat-daily-event-images.s3-ap-southeast-2.amazonaws.com/ih_18.jpeg",
    status: CatStatus.inHouse,
    content:
      "铲屎的搞了个迷宫一样的饭碗，家里怕是快揭不开锅了，想限制我。就这？",
    result: "",
    yesEffects: [
      {
        __typename: "EventEffect",
        key: CatAttribute.knowledge,
        delta: 10,
      },
      {
        __typename: "EventEffect",
        key: CatAttribute.health,
        delta: -6,
      },
    ],
    noEffects: null,
    yesItemName: null,
    isChildEvent: false,
    yesEventID: null,
    noEventID: null,
    newStatus: null,
    yesRequirements: null,
    noRequirements: null,
    repeatable: false,
    sceneId: null,
  },
  {
    id: "ih_19",
    title: "",
    imgUrl:
      "https://cat-daily-event-images.s3-ap-southeast-2.amazonaws.com/ih_19.jpg",
    status: CatStatus.inHouse,
    content: "发现一副炫酷的眼镜，要不要试试？",
    result: "获得道具：炫酷眼镜",
    yesEffects: [
      {
        __typename: "EventEffect",
        key: CatAttribute.knowledge,
        delta: 8,
      },
    ],
    noEffects: [],
    yesItemName: "炫酷眼镜",
    isChildEvent: false,
    yesEventID: null,
    noEventID: null,
    newStatus: null,
    yesRequirements: null,
    noRequirements: null,
    repeatable: false,
    sceneId: null,
  },
  {
    id: "ih_20",
    title: "",
    imgUrl:
      "https://cat-daily-event-images.s3-ap-southeast-2.amazonaws.com/ih_20.jpg",
    status: CatStatus.inHouse,
    content: "要洗澡？？？",
    result: "奇怪的清洁度和免疫力上升了！",
    yesEffects: [
      {
        __typename: "EventEffect",
        key: CatAttribute.health,
        delta: 9,
      },
      {
        __typename: "EventEffect",
        key: CatAttribute.wilderness,
        delta: -15,
      },
    ],
    noEffects: null,
    yesItemName: null,
    isChildEvent: false,
    yesEventID: null,
    noEventID: null,
    newStatus: null,
    yesRequirements: null,
    noRequirements: null,
    repeatable: false,
    sceneId: null,
  },
  {
    id: "ih_21",
    title: "",
    imgUrl:
      "https://cat-daily-event-images.s3-ap-southeast-2.amazonaws.com/ih_21.jpg",
    status: CatStatus.inHouse,
    content: "陪铲屎的玩，也是本喵的职责",
    result: "",
    yesEffects: [
      {
        __typename: "EventEffect",
        key: CatAttribute.wilderness,
        delta: -13,
      },
      {
        __typename: "EventEffect",
        key: CatAttribute.knowledge,
        delta: 7,
      },
    ],
    noEffects: [
      {
        __typename: "EventEffect",
        key: CatAttribute.wilderness,
        delta: 8,
      },
    ],
    yesItemName: null,
    isChildEvent: false,
    yesEventID: null,
    noEventID: null,
    newStatus: null,
    yesRequirements: null,
    noRequirements: null,
    repeatable: false,
    sceneId: null,
  },
  {
    id: "ih_22",
    title: "",
    imgUrl:
      "https://cat-daily-event-images.s3-ap-southeast-2.amazonaws.com/ih_22.jpg",
    status: CatStatus.inHouse,
    content: "衣带渐窄终不悔，为罐消得猫憔悴",
    result: "",
    yesEffects: [
      {
        __typename: "EventEffect",
        key: CatAttribute.health,
        delta: -14,
      },
    ],
    noEffects: [
      {
        __typename: "EventEffect",
        key: CatAttribute.health,
        delta: -14,
      },
    ],
    yesItemName: null,
    isChildEvent: false,
    yesEventID: null,
    noEventID: null,
    newStatus: null,
    yesRequirements: null,
    noRequirements: null,
    repeatable: false,
    sceneId: null,
  },
  {
    id: "ih_23",
    title: "",
    imgUrl:
      "https://cat-daily-event-images.s3-ap-southeast-2.amazonaws.com/ih_23.jpg",
    status: CatStatus.inHouse,
    content: "听说把这个东西放在头顶，能向世界发出自己的讯号",
    result: "",
    yesEffects: [
      {
        __typename: "EventEffect",
        key: CatAttribute.knowledge,
        delta: -12,
      },
    ],
    noEffects: [],
    yesItemName: null,
    isChildEvent: false,
    yesEventID: null,
    noEventID: null,
    newStatus: null,
    yesRequirements: null,
    noRequirements: null,
    repeatable: false,
    sceneId: null,
  },
  {
    id: "ih_24",
    title: "",
    imgUrl:
      "https://cat-daily-event-images.s3-ap-southeast-2.amazonaws.com/ih_24.jpg",
    status: CatStatus.inHouse,
    content: "家里有股奇怪的气味，铲屎的躺在屋里不动弹。要去调查一下吗？",
    result: "",
    yesEffects: [],
    noEffects: [],
    yesItemName: null,
    isChildEvent: false,
    yesEventID: "ih_26",
    noEventID: "ih_25",
    newStatus: null,
    yesRequirements: null,
    noRequirements: null,
    repeatable: false,
    sceneId: null,
  },
  {
    id: "ih_25",
    title: "",
    imgUrl:
      "https://cat-daily-event-images.s3-ap-southeast-2.amazonaws.com/ih_25.jpg",
    status: CatStatus.inHouse,
    content: "由于煤气泄漏，铲屎官和你成了植物姐妹",
    result: "",
    yesEffects: [],
    noEffects: [],
    yesItemName: null,
    isChildEvent: true,
    yesEventID: null,
    noEventID: null,
    newStatus: CatStatus.finished,
    yesRequirements: null,
    noRequirements: null,
    repeatable: false,
    sceneId: null,
  },
  {
    id: "ih_26",
    title: "",
    imgUrl:
      "https://cat-daily-event-images.s3-ap-southeast-2.amazonaws.com/ih_26.jpg",
    status: CatStatus.inHouse,
    content: "巨臭的气味不断从这个铁架子上传出来。要想想办法！",
    result: "",
    yesEffects: [],
    noEffects: [],
    yesItemName: null,
    isChildEvent: true,
    yesEventID: "ih_27",
    noEventID: "ih_25",
    newStatus: null,
    yesRequirements: [
      {
        __typename: "AttributeRequirement",
        key: CatAttribute.knowledge,
        minimum: 60,
        maximum: null,
      },
    ],
    noRequirements: null,
    repeatable: false,
    sceneId: null,
  },
  {
    id: "ih_27",
    title: "",
    imgUrl:
      "https://cat-daily-event-images.s3-ap-southeast-2.amazonaws.com/ih_27.jpg",
    status: CatStatus.inHouse,
    content:
      "依靠高超的智商模仿人类拨打了急救电话。铲屎的很感动，送给你一块拼图",
    result: "",
    yesEffects: [],
    noEffects: [],
    yesItemName: "拼图碎片2",
    isChildEvent: true,
    yesEventID: null,
    noEventID: null,
    newStatus: null,
    yesRequirements: null,
    noRequirements: null,
    repeatable: false,
    sceneId: null,
  },
  {
    id: "ih_28",
    title: "",
    imgUrl:
      "https://cat-daily-event-images.s3-ap-southeast-2.amazonaws.com/ih_28.jpg",
    status: CatStatus.inHouse,
    content: "铲屎的领回家一只高大的挖煤工，接受它吗？",
    result: "",
    yesEffects: [],
    noEffects: [],
    yesItemName: null,
    isChildEvent: true,
    yesEventID: null,
    noEventID: null,
    newStatus: CatStatus.finished,
    yesRequirements: [
      {
        __typename: "AttributeRequirement",
        key: CatAttribute.wilderness,
        minimum: null,
        maximum: 75,
      },
    ],
    noRequirements: null,
    repeatable: false,
    sceneId: "sce_1",
  },
];

export default inHouseEvents;
